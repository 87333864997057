import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPharmacyDeliveryAllUnit, getPharmacyList } from "../../../actions";
import Pagination from "../../../components/Pagination";
import DashboardLayout from "../Layout";
import queryString from "query-string";
import moment from "moment";
import { SelectOptions } from "../../../components/Input";
import { Link } from "react-router-dom";

const PharmacyDeliveryAllUnit = () => {
  const partnerId = localStorage.getItem("parentId");
  const [search, setSearch] = useState("");
  const [hospital, setHospital] = useState();
  const [status, setStatus] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const searchKey = useRef("");
  const startDate = useRef("");
  const endDate = useRef("");
  const pharmacyDeliveryAllUnit = useSelector(
    (state) => state.pharmacyDeliveryAllUnit
  );
  const pharmacyDelivery = useSelector((state) => state.pharmacyDelivery);
  const dispatch = useDispatch();

  const role = JSON.parse(localStorage.getItem("user"));
  const roleUser = role.user_role_management_id.id;

  useEffect(() => {
    dispatch(getPharmacyDeliveryAllUnit(search));
    if(roleUser == 43 || roleUser == 44) {
      dispatch(getPharmacyList());
    }
  }, [search]);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalData: pharmacyDeliveryAllUnit.totalData,
    });
  }, [pharmacyDeliveryAllUnit.totalData]);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const handleAutoComplete = () => {
    setTimeout(() => {
      setPagination({
        ...pagination,
        page: 1,
      });

      let values = {
        partnerId: partnerId,
        searchKey: searchKey.current.value,
        page: pagination.page,
        limit: pagination.limit,
      };

      if (startDate.current.value !== "") {
        values.startDate = startDate.current.value;
      }

      if (endDate.current.value !== "") {
        values.endDate = endDate.current.value;
      }

      let query = queryString.stringify(values);
      setSearch(query);
    }, 1000);
  };

  const handleReset = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      partnerId: partnerId,
      searchKey: searchKey.current.value,
      page: pagination.page,
      limit: pagination.limit,
    };
    if (startDate.current.value !== "") {
      startDate.current.value = "";
    }
    if (endDate.current.value !== "") {
      endDate.current.value = "";
    }
    setHospital(null);
    setStatus(null);

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleSearch = (page, limit) => {
    let values = {
      partnerId: partnerId,
      searchKey: searchKey.current.value,
    };
    if (page) {
      values.page = page;
    } else {
      values.page = 1;
      setPagination({
        ...pagination,
        page: 1,
      });
    }
    if (limit) {
      values.limit = limit;
    }
    if (startDate.current.value !== "") {
      values.startDate = startDate.current.value;
    }
    if (endDate.current.value !== "") {
      values.endDate = endDate.current.value;
    }
    if (hospital?.value) {
      values.hospital = hospital?.value;
    }
    if (status?.value) {
      values.status = status?.value;
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const renderPharmacyAllUnit = () => {
    return pharmacyDeliveryAllUnit.loading ? (
      <tr>
        <td colSpan="10">
          <Loading />
        </td>
      </tr>
    ) : (
      <>
        {pharmacyDeliveryAllUnit.orders &&
        pharmacyDeliveryAllUnit.orders.length > 0 ? (
          pharmacyDeliveryAllUnit.orders.map((data, index) => (
            <tr key={data.order_id}>
              <td>{(pagination.page - 1) * 10 + index + 1}</td>
              <td>
                <Link
                  to={{
                    pathname: "/pharmacy-delivery-all-unit/detail",
                    state: data.order_id,
                  }}
                >
                  <span style={{ color: "blue", textDecoration: "underline" }}>
                    {data.order_id}
                  </span>
                </Link>
              </td>
              <td>{data.patient_name ? data.patient_name : "-"}</td>
              <td>{data.order_status ? data.order_status : "-"}</td>
              <td>
                {data.created_at
                  ? moment(data.created_at).format("DD MMM YYYY HH:mm:ss")
                  : "-"}
              </td>
              <td>
                {data.request_pickup_at
                  ? moment(data.request_pickup_at).format("DD MMM YYYY HH:mm:ss")
                  : "-"}
              </td>
              <td>{data.booking_id ? data.booking_id : "-"}</td>
              <td>{data.driver_name ? data.driver_name : "-"}</td>
              <td>
                {data.driver_phone ? (
                  <a
                    style={{ color: "blue", textDecoration: "underline" }}
                    href={data.driver_phoneWa}
                    target="_blank"
                  >
                    {"+62" + data.driver_phone}{" "}
                  </a>
                ) : (
                  "-"
                )}
              </td>
              <td>{data.pharmacyName ? data.pharmacyName : "-"}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="10">Empty data</td>
          </tr>
        )}
      </>
    );
  };

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>Seluruh Data Order</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>

        <div className="row">
          <div className="col-md-8">
            <span id="finance-title" className="mb10">
              Delivered
            </span>
          </div>

          <div style={{ marginBottom: "1em" }} className="col-md-4">
            <div className="input-group">
              <input
                type="text"
                className="form-control table-search-field"
                placeholder="Search Patient Name/Order ID/Unit Name"
                onChange={handleAutoComplete}
                ref={searchKey}
              />
            </div>
          </div>
        </div>

        <div className="row mb15">
          <div className="link-btn">
            <button
              type="button"
              className="btn btn-link no-padding"
              style={{ textDecoration: "none", boxShadow: "none" }}
              data-toggle="collapse"
              data-target="#collapseDetailSchedule"
            >
              Filter By
              <i className="fa fa-angle-down" style={{ marginLeft: "10px" }} />
            </button>
          </div>

          <div className="col">
            <hr />
          </div>
        </div>

        <div className="collapse" id="collapseDetailSchedule">
          <div id="filterForm" className="row filter-by">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  <div className="mb10">Date From</div>
                  <input type="date" className="form-control" ref={startDate} />
                </div>

                <div className="col-md-3">
                  <div className="mb10">Date To</div>
                  <input type="date" className="form-control" ref={endDate} />
                </div>

                <div className="col-md-3">
                  <div className="mb10">Status</div>
                  <SelectOptions
                    options={[
                      { value: 21, label: "Belum Dibayar" },
                      { value: 9, label: "Pending Bayar" },
                      { value: 22, label: "Sudah Dibayar/Bayar di RS" },
                      { value: 23, label: "Dalam Pengiriman" },
                      { value: 99, label: "Selesai" },
                      { value: 89, label: "Dibatalkan" },
                    ]}
                    value={status}
                    setValue={setStatus}
                    isClearable={true}
                  />
                </div>

                {(roleUser == 43 || roleUser == 44) && (
                <div className="col-md-3">
                  <div className="mb10">Unit</div>
                  <SelectOptions
                    options={pharmacyDelivery.pharmacies}
                    value={hospital}
                    setValue={setHospital}
                    isClearable={true}
                  />
                </div>
                )}
              </div>
            </div>
          </div>

          <div className="text-right" style={{ marginTop: "25px" }}>
            <button
              type="button"
              className="btn btn-secondary"
              style={{ width: "100px", marginRight: "5px" }}
              onClick={() => handleReset()}
            >
              Reset
            </button>

            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "100px" }}
              onClick={() => handleSearch()}
            >
              Apply
            </button>
          </div>

          <div style={{ width: "100%" }}>
            <hr />
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-header">
              <tr>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "45px" }}
                >
                  No
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "100px" }}
                >
                  Order ID
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Patient Name
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Order Status
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Created At
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Request Pickup Time
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Delivery Order ID
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Driver Name
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Driver Phone
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Unit Name
                </th>
              </tr>
            </thead>
            <tbody>{renderPharmacyAllUnit()}</tbody>
          </table>
        </div>

        {pharmacyDeliveryAllUnit.totalData > 10 ? (
          <Pagination
            props={pagination}
            setProps={setPagination}
            onChange={(page, limit) => {
              handleSearch(page, limit);
            }}
          />
        ) : null}
      </div>
    </DashboardLayout>
  );
};

export default PharmacyDeliveryAllUnit;
