import axios from "../helpers/axios";
import axiosParent from "../helpers/axiosParent";
import { pharmacyDeliveryConstants } from "./constants";

export const getAllReportPharmacyOffline = (partnerId, search) => {
  return async (dispatch) => {
    dispatch({
      type: pharmacyDeliveryConstants.GET_ALL_REPORT_PHARMACY_OFFLINE_REQUEST,
    });
    const res = await axios.get(
      `/Order/getPharmacyDeliveryOffline?${partnerId}&${search}`
    );
    if (res.status === 200) {
      dispatch({
        type: pharmacyDeliveryConstants.GET_ALL_REPORT_PHARMACY_OFFLINE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: pharmacyDeliveryConstants.GET_ALL_REPORT_PHARMACY_OFFLINE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getAllPharmacyDelivery = (partnerId, search) => {
  return async (dispatch) => {
    dispatch({
      type: pharmacyDeliveryConstants.GET_ALL_PHARMACY_DELIVERY_REQUEST,
    });
    const res = await axios.get(
      `/Order/getPharmacyDelivery?${partnerId}&${search}`
    );
    if (res.status === 200) {
      dispatch({
        type: pharmacyDeliveryConstants.GET_ALL_PHARMACY_DELIVERY_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: pharmacyDeliveryConstants.GET_ALL_PHARMACY_DELIVERY_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getDetailPharmacy = (id, partnerId) => {
  return async (dispatch) => {  
    dispatch({ type: pharmacyDeliveryConstants.GET_PHARMACY_DETAIL_REQUEST });
    const res = await axios.get(
      `/Order/getDetailPharmacyDelivery/${id}?${partnerId}`
    );
    if (res.status === 200) {
      dispatch({
        type: pharmacyDeliveryConstants.GET_PHARMACY_DETAIL_SUCCESS,
        payload: { details: res.data },
      });
    } else {
      dispatch({
        type: pharmacyDeliveryConstants.GET_PHARMACY_DETAIL_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const gosendPickup = (form) => {
  return async (dispatch) => {
    dispatch({ type: pharmacyDeliveryConstants.PHARMACY_PICKUP_REQUEST });
    const res = await axiosParent.post("/Cms/Gosend/makeBooking", form);
    if (res.status === 200) {
      dispatch({
        type: pharmacyDeliveryConstants.PHARMACY_PICKUP_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: pharmacyDeliveryConstants.PHARMACY_PICKUP_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const grabExpressPickup = (form) => {
  return async (dispatch) => {
    dispatch({ type: pharmacyDeliveryConstants.PHARMACY_PICKUP_REQUEST });
    const res = await axiosParent.post("/Cms/GrabExpress/requestDelivery", form);
    if (res.status === 200) {
      dispatch({
        type: pharmacyDeliveryConstants.PHARMACY_PICKUP_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: pharmacyDeliveryConstants.PHARMACY_PICKUP_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const anterAjaPickup = (form) => {
  return async (dispatch) => {
    dispatch({ type: pharmacyDeliveryConstants.PHARMACY_PICKUP_REQUEST });
    const res = await axiosParent.post("/Cms/AnterAja/order", form);
    if (res.status === 200) {
      dispatch({
        type: pharmacyDeliveryConstants.PHARMACY_PICKUP_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: pharmacyDeliveryConstants.PHARMACY_PICKUP_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const lalamovePickup = (form) => {
  return async (dispatch) => {
    dispatch({ type: pharmacyDeliveryConstants.PHARMACY_PICKUP_REQUEST });
    const res = await axiosParent.post("/Delivery/Lalamove/placeOrder", form);
    if (res.status === 200) {
      dispatch({
        type: pharmacyDeliveryConstants.PHARMACY_PICKUP_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: pharmacyDeliveryConstants.PHARMACY_PICKUP_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const jntPickup = (form) => {
  return async (dispatch) => {
    dispatch({ type: pharmacyDeliveryConstants.PHARMACY_PICKUP_REQUEST });
    const res = await axiosParent.post("/Cms/Jnt/order", form);
    if (res.status === 200) {
      dispatch({
        type: pharmacyDeliveryConstants.PHARMACY_PICKUP_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: pharmacyDeliveryConstants.PHARMACY_PICKUP_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};
export const submitPharmacyNote = (form) => {
  return async (dispatch) => {
    dispatch({ type: pharmacyDeliveryConstants.SUBMIT_PHARMACY_NOTE_REQUEST });
    const res = await axios.post("/Order/submitPharmacyNote", form);
    if (res.status === 200) {
      dispatch({
        type: pharmacyDeliveryConstants.SUBMIT_PHARMACY_NOTE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: pharmacyDeliveryConstants.SUBMIT_PHARMACY_NOTE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const findPharmacy = () => {
  return async (dispatch) => {
    dispatch({ type: pharmacyDeliveryConstants.GET_PHARMACY_REQUEST });
    const res = await axios.get(`/Order/findPharmacy`);
    if (res.status === 200) {
      dispatch({
        type: pharmacyDeliveryConstants.GET_PHARMACY_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: pharmacyDeliveryConstants.GET_PHARMACY_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};
  
export const getReportLink = (partnerId) => {
  return async (dispatch) => {
    dispatch({
      type: pharmacyDeliveryConstants.GET_PHARMACY_REPORT_LINK_REQUEST,
    });
    const res = await axios.get(`/Pharmacy/Report/Link?${partnerId}`);
    if (res.status === 200) {
      dispatch({
        type: pharmacyDeliveryConstants.GET_PHARMACY_REPORT_LINK_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: pharmacyDeliveryConstants.GET_PHARMACY_REPORT_LINK_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const tikiPickup = (form) => {
  return async (dispatch) => {
    dispatch({ type: pharmacyDeliveryConstants.PHARMACY_PICKUP_REQUEST });
    const res = await axiosParent.post("/Cms/Tiki/order", form);
    if (res.status === 200) {
      dispatch({
        type: pharmacyDeliveryConstants.PHARMACY_PICKUP_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: pharmacyDeliveryConstants.PHARMACY_PICKUP_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const submitAddressNote = (form) => {
  return async (dispatch) => {
    dispatch({ type: pharmacyDeliveryConstants.SUBMIT_ADDRESS_NOTE_REQUEST });
    const res = await axios.post("/Order/submitPharmacyAddressNote", form);
    if (res.status === 200) {
      dispatch({
        type: pharmacyDeliveryConstants.SUBMIT_ADDRESS_NOTE_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: pharmacyDeliveryConstants.SUBMIT_ADDRESS_NOTE_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};

export const getPharmacyList = () => {
  return async (dispatch) => {
    dispatch({
      type: pharmacyDeliveryConstants.GET_PHARMACY_DELIVERY_AIDO_REQUEST,
    });
    const res = await axios.get(`/Pharmacy/List`);
    if (res.status === 200) {
      dispatch({
        type: pharmacyDeliveryConstants.GET_PHARMACY_DELIVERY_AIDO_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: pharmacyDeliveryConstants.GET_PHARMACY_DELIVERY_AIDO_FAILURE,
        payload: { error: res.data.error },
      });
    }
  };
};