import React, { createRef, useEffect, useState } from "react";
import moment from "moment";

const FormJnt = (props) => {
  const nf = new Intl.NumberFormat();
  var insuranceValue = props.detail.product_price
  if(props.detail.insuranceValue){
    insuranceValue = props.detail.insuranceValue
  }
  return (
    <>
      <div className="row">
        <div className="col-5">
          <div className="row mt-2">
            <div
              className="col-4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>Tanggal Resep</div>
              <div>:</div>
            </div>
            <div className="col-8">
              {props.detail.order_type == 2 || props.detail.order_type == 13
                ? props.detail.prescriptionDate
                  ? props.detail.prescriptionDate
                  : ""
                : props.detail.order_type == 19
                ? props.detail.orderDate
                  ? props.detail.orderDate
                  : ""
                : ""}
            </div>
          </div>

          <div className="row mt-2">
            <div
              className="col-4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>Nama Dokter</div>
              <div>:</div>
            </div>
            <div className="col-8">
              {props.detail.order_type == 2
                ? props.detail.doctor_name
                  ? props.detail.doctor_name
                  : ""
                : props.detail.order_type == 19
                ? props.detail.remarks
                  ? props.detail.remarks
                  : ""
                : props.detail.order_type == 13
                ? ""
                : ""}
            </div>
          </div>

          <div className="row mt-2">
            <div
              className="col-4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>Nama Pasien</div>
              <div>:</div>
            </div>
            <div className="col-8">
              {props.detail.destinationName ? props.detail.destinationName : "-"}
            </div>
          </div>

          <div className="row mt-2">
            <div
              className="col-4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>No. HP Pasien</div>
              <div>:</div>
            </div>
            <div className="col-8">
              {props.detail.destinationContactPhone
                ? `+62${props.detail.destinationContactPhone}`
                : "-"}
            </div>
          </div>

          <div className="row mt-2">
            <div
              className="col-4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>Titik Pengiriman di Peta</div>
              <div>:</div>
            </div>
            <div className="col-8">
              {props.detail.destinationAddress ? props.detail.destinationAddress : "-"}
            </div>
          </div>

          <div className="row mt-2">
            <div
              className="col-4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>Alamat Pengiriman props</div>
              <div>:</div>
            </div>
            <div className="col-8">
              {props.detail.destinationNote ? props.detail.destinationNote : "-"}
            </div>
          </div>
          <div className="row mt-2">
            <div
              className="col-4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>Order ID</div>
              <div>:</div>
            </div>
            <div className="col-8">{props.detail.order_id}</div>
          </div>

          <div className="row mt-2">
            <div
              className="col-4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>Metode Pengiriman</div>
              <div>:</div>
            </div>
            <div className="col-8">{props.detail.name ? props.detail.name : "-"}</div>
          </div>

          <div className="row mt-2">
            <div
              className="col-4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>Jadwal Pengiriman</div>
              <div>:</div>
            </div>
            <div className="col-8">
              {props.detail.schedule_time
                ? `${moment(props.detail.schedule_date).format(
                    "DD MMM YYYY"
                  )} ${props.detail.schedule_time.substr(0, 5)}`
                : "Segera"}
            </div>
          </div>

          <div className="row mt-2">
            <div
              className="col-4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>Nama Driver</div>
              <div>:</div>
            </div>
          </div>

          <div className="row mt-2">
            <div
              className="col-4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>No. Hp Driver</div>
              <div>:</div>
            </div>
          </div>

          <div className="row mt-2">
            <div
              className="col-4"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>Catatan Farmasi</div>
              <div>:</div>
            </div>
            <div className="col-8">
              {props.detail.pharmacy_note ? props.detail.pharmacy_note : ""}
            </div>
          </div>
        </div>
        <div className="col-7">
          <div style={{border: "1px solid red", padding: 10}}>
          <div className="row" >
            <div className="col-12" style={{textAlign: "center"}}>
              <b>
              INFO UNTUK J&T
              </b>
            </div>
          </div>
          <div className="m-1 font-weight-bold text-danger">No. Resi: {props.detail.booking_id}</div>
          <div className="row">
            <div className="col-6" style={{ display: "flex", justifyContent: "space-between" }}>
              <table>
                <tr>
                  <td><b>Pengirim</b></td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>Company</td>
                  <td>: {props.detail.originContactName ? props.detail.originContactName : "-"}</td>
                </tr>
                <tr>
                  <td>Alamat</td>
                  <td>: {props.detail.originAddress ? props.detail.originAddress : "-"}</td>
                </tr>
                <tr>
                  <td>Kode Pos</td>
                  <td>: {props.detail.originPostCode ? props.detail.originPostCode : "-"}</td>
                </tr>
                <tr>
                  <td>Telp</td>
                  <td>: {props.detail.originContactPhone ? props.detail.originContactPhone : "-"}</td>
                </tr>
              </table>
            </div>
            <div className="col-6" style={{ display: "flex", justifyContent: "space-between" }}>
            <table>
              <tr>
                <td><b>Penerima</b></td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Nama</td>
                <td>: {props.detail.destinationName ? props.detail.destinationName : "-"}</td>
              </tr>
              <tr>
                <td>Titik di Peta</td>
                <td>: {props.detail.destinationAddress ? props.detail.destinationAddress : "-"}</td>
              </tr>
              <tr>
                <td>Alamat Pengiriman</td>
                <td>: {props.detail.destinationNote ? props.detail.destinationNote : "-"}</td>
              </tr>
              <tr>
                <td>Kode Pos</td>
                <td>: {props.detail.destinationPostCode ? props.detail.destinationPostCode : "-"}</td>
              </tr>
              <tr>
                <td>Telp</td>
                <td>: {props.detail.destinationContactPhone ? `+62${props.detail.destinationContactPhone}` : "-"}
                </td>
              </tr>
            </table>
            </div>
          </div>
          <div className="row">
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default FormJnt;
