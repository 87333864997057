import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPharmacyWaitingDelivery, getPharmacyList } from "../../../actions";
import Pagination from "../../../components/Pagination";
import DashboardLayout from "../Layout";
import queryString from "query-string";
import moment from "moment";
import { Link } from "react-router-dom";
import { SelectOptions } from "../../../components/Input";

const PharmacyWatingDelivery = () => {
  const [search, setSearch] = useState("");
  const [hospital, setHospital] = useState();
  const partnerId = localStorage.getItem("parentId");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalData: 0,
  });
  const searchKey = useRef("");
  const startDate = useRef("");
  const endDate = useRef("");
  const pharmacyWaitingDelivery = useSelector(
    (state) => state.pharmacyWaitingDelivery
  );
  const pharmacyDelivery = useSelector((state) => state.pharmacyDelivery);
  const dispatch = useDispatch();

  const role = JSON.parse(localStorage.getItem("user"));
  const roleUser = role.user_role_management_id.id;

  useEffect(() => {
    let values = {
        partnerId: localStorage.getItem("parentId"),
      },
      query = queryString.stringify(values);
    dispatch(getAllPharmacyWaitingDelivery(query, search));
    if(roleUser == 43 || roleUser == 44) {
      dispatch(getPharmacyList());
    }
  }, [search]);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalData: pharmacyWaitingDelivery.totalData,
    });
  }, [pharmacyWaitingDelivery.totalData]);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const handleAutoComplete = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      searchKey: searchKey.current.value,
      page: pagination.page,
      limit: pagination.limit,
    };
    if (startDate.current.value !== "") {
      values.startDate = startDate.current.value;
    }
    if (endDate.current.value !== "") {
      values.endDate = endDate.current.value;
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleReset = () => {
    setPagination({
      ...pagination,
      page: 1,
    });

    let values = {
      searchKey: searchKey.current.value,
      page: pagination.page,
      limit: pagination.limit,
    };
    if (startDate.current.value !== "") {
      startDate.current.value = "";
    }
    if (endDate.current.value !== "") {
      endDate.current.value = "";
    }
    setHospital(null);

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const handleSearch = (page, limit) => {
    let values = {
      searchKey: searchKey.current.value,
    };
    if (page) {
      values.page = page;
    } else {
      values.page = 1;
      setPagination({
        ...pagination,
        page: 1,
      });
    }
    if (limit) {
      values.limit = limit;
    }
    if (startDate.current.value !== "") {
      values.startDate = startDate.current.value;
    }
    if (endDate.current.value !== "") {
      values.endDate = endDate.current.value;
    }
    if (hospital?.value) {
      values.hospital = hospital?.value;
    }

    let query = queryString.stringify(values);
    setSearch(query);
  };

  const renderPharmacy = () => {
    return pharmacyWaitingDelivery.loading ? (
      <tr>
        <td colSpan={(roleUser == 43 || roleUser == 44) ? "13" : "12"}>
          <Loading />
        </td>
      </tr>
    ) : (
      <>
        {pharmacyWaitingDelivery.orders.length > 0 ? (
          pharmacyWaitingDelivery.orders.map((pharmacy, index) => (
            <tr key={pharmacy.order_id}>
              <td>{(pagination.page - 1) * 10 + index + 1}</td>
              <td>{pharmacy.order_id ? pharmacy.order_id : "-"}</td>
              <td>
                {pharmacy.order_type == 2
                  ? "Tele"
                  : pharmacy.order_type == 13
                  ? "MySiloam"
                  : pharmacy.order_type == 14
                  ? "Offline"
                  : "Unit"}
              </td>
              <td>{pharmacy.patient_name ? pharmacy.patient_name : "-"}</td>
              <td>
                {pharmacy.birthday
                  ? moment(pharmacy.birthday).format("ll")
                  : "-"}
              </td>
              <td>
                {pharmacy.created_at
                  ? moment(pharmacy.created_at).format("ll")
                  : "-"}
              </td>
              {(roleUser == 43 || roleUser == 44) && (
              <td>
                {pharmacy.request_pickup_at
                  ? moment(pharmacy.request_pickup_at).format("DD MMM YYYY HH:mm:ss")
                  : "-"}
              </td>
              )}
              <td>{pharmacy.booking_id ? pharmacy.booking_id : "-"}</td>
              <td>{pharmacy.driver_name ? pharmacy.driver_name : "-"}</td>
              <td>
                {pharmacy.driver_phone ? (
                  <a
                    style={{ color: "blue", textDecoration: "underline" }}
                    href={pharmacy.driver_phoneWa}
                    target="_blank"
                  >
                    {"+62" + pharmacy.driver_phone}{" "}
                  </a>
                ) : (
                  "-"
                )}
              </td>
              {(roleUser == 43 || roleUser == 44) ? (
                <td>{pharmacy.pharmacyName ? pharmacy.pharmacyName : "-"}</td>
              ) : (
                <td>{pharmacy.schedule_time ? "YES" : "NO"}</td>
              )}
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <Link
                    to={{
                      pathname: "/pharmacy-waiting-delivery/detail",
                      state: pharmacy.order_id,
                    }}
                  >
                    <button
                      type="button"
                      className="btn btn-block btn-outline-primary"
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      Detail
                    </button>
                  </Link>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={(roleUser == 43 || roleUser == 44) ? "13" : "12"}>Empty data</td>
          </tr>
        )}
      </>
    );
  };

  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>Dalam Proses Pengiriman</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="alert alert-info" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "15px", marginBottom: "10px" }}>
            Jika anda atau pasien ada yang mengalami kendala dengan aplikasi
            aido, silahkan hubungi aido support dengan click chat aido support.
            <br />
            <a
              href="https://wa.me/628118481436"
              style={{ color: "#51BED3" }}
              target="blank"
            >
              Chat Aido Support
            </a>
          </p>
        </div>

        <div className="row">
          <div className="col-md-8">
            <span id="finance-title" className="mb10">
              Dalam Proses Pengiriman
            </span>
          </div>

          <div style={{ marginBottom: "1em" }} className="col-md-4">
            <div className="input-group">
              <input
                type="text"
                className="form-control table-search-field"
                placeholder={(roleUser == 43 || roleUser == 44) ? "Search Patient Name/Order ID/Unit Name" : "Search Patient Name/Order ID"}
                onChange={handleAutoComplete}
                ref={searchKey}
              />
            </div>
          </div>
        </div>

        <div className="row mb15">
          <div className="link-btn">
            <button
              type="button"
              className="btn btn-link no-padding"
              style={{ textDecoration: "none", boxShadow: "none" }}
              data-toggle="collapse"
              data-target="#collapseDetailSchedule"
            >
              Filter By
              <i className="fa fa-angle-down" style={{ marginLeft: "10px" }} />
            </button>
          </div>

          <div className="col">
            <hr />
          </div>
        </div>

        <div className="collapse" id="collapseDetailSchedule">
          <div id="filterForm" className="row filter-by">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  <div className="mb10">Date From</div>
                  <input type="date" className="form-control" ref={startDate} />
                </div>

                <div className="col-md-4">
                  <div className="mb10">Date To</div>
                  <input type="date" className="form-control" ref={endDate} />
                </div>

                {(roleUser == 43 || roleUser == 44) && 
                <div className="col-md-4">
                  <div className="mb10">Unit</div>
                  <SelectOptions
                    options={pharmacyDelivery.pharmacies}
                    value={hospital}
                    setValue={setHospital}
                    isClearable={true}
                  />
                </div>
                }
              </div>
            </div>
          </div>

          <div className="text-right" style={{ marginTop: "25px" }}>
            <button
              type="button"
              className="btn btn-secondary"
              style={{ width: "100px", marginRight: "5px" }}
              onClick={() => handleReset()}
            >
              Reset
            </button>

            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "100px" }}
              onClick={() => handleSearch()}
            >
              Apply
            </button>
          </div>

          <div style={{ width: "100%" }}>
            <hr />
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-header">
              <tr>
                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "45px" }}
                >
                  No
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "100px" }}
                >
                  Order Id Pharmacy
                </th>

                <th
                  rowSpan={2}
                  className="table-header-middle"
                  style={{ width: "100px" }}
                >
                  Channel
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Patient Name
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Patient DOB
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Created at
                </th>

                {(roleUser == 43 || roleUser == 44) && (
                  <th rowSpan={2} className="table-header-middle">
                    Request Pickup Time
                  </th>
                )}

                <th rowSpan={2} className="table-header-middle">
                  Delivery Order Id
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Driver Name
                </th>

                <th rowSpan={2} className="table-header-middle">
                  Driver Phone
                </th>

                {(roleUser == 43 || roleUser == 44) ? (
                  <th rowSpan={2} className="table-header-middle">
                    Unit Name
                  </th>
                ) : (
                  <th rowSpan={2} className="table-header-middle">
                    Scheduled
                  </th>
                )}

                <th rowSpan={2} className="table-header-middle">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>{renderPharmacy()}</tbody>
          </table>
        </div>

        {pharmacyWaitingDelivery.totalData > 10 ? (
          <Pagination
            props={pagination}
            setProps={setPagination}
            onChange={(page, limit) => {
              handleSearch(page, limit);
            }}
          />
        ) : null}
      </div>
    </DashboardLayout>
  );
};

export default PharmacyWatingDelivery;
