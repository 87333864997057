import React, { useEffect, useState } from "react";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getDetailPharmacy,
  gosendPickup,
  anterAjaPickup,
  grabExpressPickup,
  cancelOrder,
  lalamovePickup,
  jntPickup,
  submitAddressNote,
  tikiPickup,
  findConfig
} from "../../../../actions";
import DashboardLayout from "../../Layout";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import Swal from "sweetalert2";
import moment from "moment";
import { formatCurrency } from "../../../../helpers/currency";

const PharmacyDeliveryDetail = () => {
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationCancel, setConfirmationCancel] = useState(false);
  const [confirmationEditAddress, setConfirmationEditAddress] = useState(false);
  const pharmacyDelivery = useSelector((state) => state.pharmacyDelivery);
  const { data: config } = useSelector((state) => state.config);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [cancelField, setCancelField] = useState({
    reason: "",
  });
  const [editAddressField, setEditAddressField] = useState({
    note: "",
  });
  const [mapAddress, setMapAddress] = useState("");

  const role = JSON.parse(localStorage.getItem("user"));
  const roleUser = role.user_role_management_id.id;
  const [awbModal, setAwbModal] = useState(false);
  const [customFields, setCustomFields] = useState({});
  const [reason, setReason] = useState('')
  const [disableInput, setDisableInput] = useState(true)
  const [reasons, setReasons] = useState(["Lainnya"])
  const [otherReason, setOtherReason]= useState(false)

  useEffect(() => {
    let values = {
      partnerId: localStorage.getItem("parentId"),
    };

    let query = queryString.stringify(values);
    dispatch(getDetailPharmacy(state, query));
    dispatch(findConfig("key=CANCEL_REASON"))
  }, []);

  useEffect(() => {
    if (pharmacyDelivery.details) {
      let detail =
        pharmacyDelivery.details.length != 0
          ? pharmacyDelivery.details[0]
          : pharmacyDelivery.details;
      setEditAddressField({note: detail.destinationNote});
      setMapAddress(detail.destinationAddress)
      if(detail.customFields){
        setCustomFields(JSON.parse(detail.customFields))
      }
    }

  }, [pharmacyDelivery.details]);

  useEffect(() => {
    const { value = null } = config
    if (value) {
      setReasons(value.split(/,(?![^()]*\))/).map(item => item.trim()))
    }
  }, [config.value])

  const handleCancelOption = (e) => {
    setReason(e.target.value)
    if (e.target.value.replace(/\s+/g, '') == 'Lainnya') {
      setCancelField({
        reason: "",
      })
      setDisableInput(false)
      setOtherReason(true)
    } else {
      setCancelField({
        reason: e.target.value,
      })
      setDisableInput(true)
    }
  }

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  const renderPrint = () => {
    var detail =
      pharmacyDelivery.details.length != 0
        ? pharmacyDelivery.details[0]
        : pharmacyDelivery.details;
    return roleUser != 44 ?
      (
        pharmacyDelivery.loading ? (
        <button
          type="button"
          className="btn btn-secondary"
          style={{ marginLeft: "10px", width: "150px" }}
          disabled
        >
          Loading...
        </button>
      ) : (
        <>
        {["TIKI", "JNT"].includes(detail?.vendor_name) && detail?.booking_id == null ?
        (
          <>
            <button type="button" onClick={() => setAwbModal(true)} className="btn btn-secondary" style={{ marginLeft: "10px", width: "150px" }}>
              Print Preview
            </button>
          </> 
        ):(
          <Link
            to={{
              pathname: "/pharmacy-delivery/print",
              search: `?id=${state}`,
            }}
            target="_blank"
          >
            <button
              type="button"
              className="btn btn-primary"
              style={{ marginLeft: "10px", width: "150px" }}
            >
              Print Preview
            </button>
          </Link>
        )
        }
        
        </>
      )
    ) : null;
  };

  const handlePickup = () => {
    setConfirmationModal(false);

    var detail =
      pharmacyDelivery.details.length != 0
        ? pharmacyDelivery.details[0]
        : pharmacyDelivery.details;

    if (detail.length == 0) {
      return Swal.fire({
        icon: "warning",
        title: "Pharmacy Delivery Data Not Found",
      });
    }

    var vendorName = detail.vendor_name ? detail.vendor_name : "";
    
    var productPrice = detail.product_price
      ? parseInt(detail.product_price)
      : 0;
    var payerNet = detail.payer_net ? parseInt(detail.payer_net) : 0;
    var totalProductPrice =
      parseInt(productPrice) + parseInt(payerNet) >= 1000
        ? parseInt(productPrice) + parseInt(payerNet)
        : 1000;
    const form = new FormData();

    if (vendorName.toLowerCase() == "gosend") {
      form.append("paymentType", 3);
      form.append("collection_location", "pickup");
      form.append("shipment_method", "Instant");
      form.append("service_type", "ignore");
      form.append("originName", detail.originContactName);
      form.append("originNote", detail.originNote);
      form.append("originContactName", detail.originContactName);
      form.append("originContactPhone", detail.originContactPhone);
      form.append("originLatLong", detail.originLatlong);
      form.append("originAddress", detail.originAddress);
      form.append("destinationName", detail.destinationName);
      form.append("destinationNote", detail.destinationNote);
      form.append("destinationContactName", detail.destinationContactName);
      form.append("destinationContactPhone", detail.destinationContactPhone);
      form.append("destinationLatLong", detail.destinationLatlong);
      form.append("destinationAddress", detail.destinationAddress);
      form.append("item", "Obat");
      form.append("storeOrderId", detail.order_id);
      form.append("applied", "false");
      form.append("fee", "0");
      form.append("product_description", "Obat");
      form.append("product_price", totalProductPrice);

      dispatch(gosendPickup(form))
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Data Created",
            showConfirmButton: false,
          });
          setTimeout(() => (window.location = "/pharmacy-delivery"), 1000);
        })
        .catch((err) => {
          if (err.response) {
            Swal.fire({
              icon: "info",
              text: err.response.data.message,
            }).then(function () {
              window.location.reload();
            });
          } else {
            Swal.fire({
              icon: "info",
              text: err.message,
            }).then(function () {
              window.location.reload();
            });
          }
        });
    } else if (vendorName.toLowerCase() == "anteraja") {
      var items = [];

      var itemsData = detail.item ? JSON.parse(detail.item) : [];

      for (var i = 0; i < itemsData.length; i++) {
        var patientNet = itemsData[i].patientNet
          ? parseInt(itemsData[i].patientNet)
          : 0;
        var payerNet = itemsData[i].payerNet
          ? parseInt(itemsData[i].payerNet)
          : 0;
        var declareVal =
          parseInt(patientNet) + parseInt(payerNet) >= 1000
            ? parseInt(patientNet) + parseInt(payerNet)
            : 1000;
        items.push({
          item_name: itemsData[i].name,
          item_desc: "Obat",
          item_category: "Obat",
          item_quantity: itemsData[i].qty ? parseInt(itemsData[i].qty) : 1,
          declared_value: declareVal,
          weight: 1000,
        });
      }

      form.append("booking_id", "AIDO-" + detail.order_id);
      form.append("invoice_no", "INV-" + detail.order_id);
      form.append("service_code", detail.vendor_type);
      form.append("parcel_total_weight", 1000);

      form.append("shipper_name", detail.originContactName);
      form.append("shipper_phone", detail.originContactPhone);
      form.append("shipper_email", detail.originEmail);
      form.append("shipper_district", detail.originDistrict);
      form.append("shipper_address", detail.originAddress);
      form.append("shipper_postcode", detail.originPostCode);
      form.append("shipper_geoloc", detail.originLatlong);

      form.append("receiver_name", detail.destinationName);
      form.append("receiver_phone", detail.destinationContactPhone);
      form.append("receiver_email", detail.destinationEmail);
      form.append("receiver_district", detail.destinationDistrict);
      form.append(
        "receiver_address",
        `${detail.destinationAddress}${
          detail.destinationNote ? ` (${detail.destinationNote})` : ""
        }`
      );
      form.append("receiver_postcode", detail.destinationPostCode);
      form.append("receiver_geoloc", detail.destinationLatlong);

      form.append("items", JSON.stringify(items));
      form.append("declared_value", totalProductPrice);
      form.append("use_insurance", true);
      form.append("expect_time", "");

      // For auto redirect to gosend
      form.append("origin_note", detail.originNote);
      form.append("destination_note", detail.destinationNote);
      form.append("destination_contact_name", detail.destinationContactName);
      form.append("destination_address", detail.destinationAddress);
      form.append("order_id", detail.order_id);

      dispatch(anterAjaPickup(form))
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Data Created",
            showConfirmButton: false,
          });
          setTimeout(() => (window.location = "/pharmacy-delivery"), 1000);
        })
        .catch((err) => {
          if (err.response) {
            Swal.fire({
              icon: "info",
              text: err.response.data.message,
            }).then(function () {
              window.location.reload();
            });
          } else {
            Swal.fire({
              icon: "info",
              text: err.message,
            }).then(function () {
              window.location.reload();
            });
          }
        });
    } else if (vendorName.toLowerCase() == "lalamove") {
      form.append("senderName", `Farmasi ${detail.originContactName}`);
      form.append("senderPhone", `+62${detail.originContactPhone}`);
      form.append("senderNote", detail.originNote);
      form.append("destinationName", detail.destinationContactName);
      form.append("destinationPhone", `+62${detail.destinationContactPhone}`);
      form.append("destinationNote", detail.destinationNote);
      form.append("senderLatLong", detail.originLatlong);
      form.append("senderAddress", detail.originAddress);
      form.append("destinationLatLong", detail.destinationLatlong);
      form.append("destinationAddress", detail.destinationAddress);
      form.append("orderId", detail.order_id);
      form.append("partnerId", detail.partnerId);
      form.append("deliveryMethodId", detail.deliveryMethodID);

      dispatch(lalamovePickup(form))
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Data Created",
            showConfirmButton: false,
          });
          setTimeout(() => (window.location = "/pharmacy-delivery"), 1000);
        })
        .catch((err) => {
          if (err.response) {
            Swal.fire({
              icon: "info",
              text: err.response.data.message,
            }).then(function () {
              window.location.reload();
            });
          } else {
            Swal.fire({
              icon: "info",
              text: err.message,
            }).then(function () {
              window.location.reload();
            });
          }
        });
    } else if (vendorName.toLowerCase() == "tiki") {
      var items = [];
      
      var itemsData = detail.item ? JSON.parse(detail.item) : [];
      for (var i = 0; i < itemsData.length; i++) {
        var patientNet = itemsData[i].patientNet
          ? parseInt(itemsData[i].patientNet)
          : 0;
        var payerNet = itemsData[i].payerNet
          ? parseInt(itemsData[i].payerNet)
          : 0;
        var declareVal =
          parseInt(patientNet) + parseInt(payerNet) >= 1000
            ? parseInt(patientNet) + parseInt(payerNet)
            : 1000;
        var address = detail.address_id
        var transport_fee = detail.transportFee
        items.push({
          item_name: itemsData[i].name,
          item_desc: "Obat",
          item_category: "Obat",
          destination:address,
          delivery_fee:transport_fee,
          item_quantity: itemsData[i].qty ? parseInt(itemsData[i].qty) : 1,
          declared_value: declareVal,
          weight: 1000,
        });
      }

      form.append("booking_id", "AIDO-" + detail.order_id);
      form.append("invoice_no", "INV-" + detail.order_id);
      form.append("service_code", detail.vendor_type);
      form.append("parcel_total_weight", 1000);
      


      form.append("shipper_name", detail.originContactName);
      form.append("shipper_phone", detail.originContactPhone);
      form.append("shipper_email", detail.originEmail);
      form.append("shipper_district", detail.originDistrict);
      form.append("shipper_address", detail.originAddress);
      form.append("shipper_postcode", detail.originPostCode);
      form.append("shipper_geoloc", detail.originLatlong);

      form.append("receiver_name", detail.destinationName);
      form.append("receiver_phone", detail.destinationContactPhone);
      form.append("receiver_email", detail.destinationEmail);
      form.append("receiver_district", detail.destinationDistrict);
      form.append("warehouse_code", detail.originWarehouse);
      form.append("receiver_address", detail.destinationNote);
      form.append("receiver_postcode", detail.destinationPostCode);
      form.append("receiver_geoloc", detail.destinationLatlong);

      form.append("items", JSON.stringify(items));
      form.append("declared_value", totalProductPrice);
      

      form.append("use_insurance", true);
      form.append("expect_time", "");

      dispatch(tikiPickup(form))
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Data Created",
            showConfirmButton: false,
          });
          setTimeout(() => (window.location = "/pharmacy-delivery"), 1000);
        })
        .catch((err) => {
          if (err.response) {
            Swal.fire({
              icon: "info",
              text: err.response.data.message,
            }).then(function () {
              window.location.reload();
            });
          } else {
            Swal.fire({
              icon: "info",
              text: err.message,
            }).then(function () {
              window.location.reload();
            });
          }
        });
    } else if (vendorName.toLowerCase() == "grab") {
      const [originLatitude, originLongitude] = detail.originLatlong.split(',');
      const [destinationLatitude, destinationLongitude] = detail.destinationLatlong.split(',');

      form.append("orderId", detail.order_id);
      form.append("serviceType", "INSTANT");
      form.append("itemName", "Medicine");
      form.append("itemDescription", "Medicine / Obat");
      form.append("originAddress", `${detail.originNote} | ${detail.originAddress}`);
      form.append("originLatitude", originLatitude);
      form.append("originLongitude", originLongitude);
      form.append("destinationAddress", `${detail.destinationNote} | ${detail.destinationAddress}`);
      form.append("destinationLatitude", destinationLatitude);
      form.append("destinationLongitude", destinationLongitude);
      form.append("recipientName", detail.destinationContactName);
      form.append("recipientPhone", `62${detail.destinationContactPhone}`);
      form.append("senderName", detail.originContactName);
      form.append("senderPhone", `62${detail.originContactPhone}`);
      form.append("productPrice", totalProductPrice);

      dispatch(grabExpressPickup(form))
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Data Created",
            showConfirmButton: false,
          });
          setTimeout(() => (window.location = "/pharmacy-delivery"), 1000);
        })
        .catch((err) => {
          if (err.response) {
            Swal.fire({
              icon: "info",
              text: err.response.data.message,
            }).then(function () {
              window.location.reload();
            });
          } else {
            Swal.fire({
              icon: "info",
              text: err.message,
            }).then(function () {
              window.location.reload();
            });
          }
        });
    } else if (vendorName.toLowerCase() == "jnt") {
      var items = [];
      
      var itemsData = detail.item ? JSON.parse(detail.item) : [];
      for (var i = 0; i < itemsData.length; i++) {
        var patientNet = itemsData[i].patientNet
          ? parseInt(itemsData[i].patientNet)
          : 0;
        var payerNet = itemsData[i].payerNet
          ? parseInt(itemsData[i].payerNet)
          : 0;
        var declareVal =
          parseInt(patientNet) + parseInt(payerNet) >= 1000
            ? parseInt(patientNet) + parseInt(payerNet)
            : 1000;
        var address = detail.address_id
        var transport_fee = detail.transportFee
        items.push({
          item_name: itemsData[i].name,
          item_desc: "Obat",
          item_category: "Obat",
          item_quantity: itemsData[i].qty ? parseInt(itemsData[i].qty) : 1,
          declared_value: declareVal,
          weight: 1
        });
      }

      form.append("shipper_name", detail.originContactName);
      form.append("shipper_phone", detail.originContactPhone);
      form.append("shipper_email", detail.originEmail);
      form.append("shipper_address", detail.originAddress);
      form.append("shipper_postcode", detail.originPostCode);

      form.append("receiver_name", detail.destinationName);
      form.append("receiver_phone", detail.destinationContactPhone);
      form.append("receiver_email", detail.destinationEmail);
      form.append("receiver_address", `${detail.destinationNote} | ${detail.destinationAddress}`);
      form.append("receiver_postcode", detail.destinationPostCode);
      form.append("receiver_address_id", address);
     
      form.append("orderId", detail.order_id);
      form.append("prescription_date", detail.prescriptionDate);
      form.append("deliveryMethodId", detail.deliveryMethodID);
      form.append("items", JSON.stringify(items));
      form.append("declared_value", totalProductPrice);
      form.append("parcel_total_weight", 1);

      dispatch(jntPickup(form))
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Data Created",
            showConfirmButton: false,
          });
          setTimeout(() => (window.location = "/pharmacy-delivery"), 1000);
        })
        .catch((err) => {
          if (err.response) {
            Swal.fire({
              icon: "info",
              text: err.response.data.message,
            }).then(function () {
              window.location.reload();
            });
          } else {
            Swal.fire({
              icon: "info",
              text: err.message,
            }).then(function () {
              window.location.reload();
            });
          }
        });
    }

  };

  const handleCancel = () => {
    if (cancelField.reason === "") {
      return Swal.fire({
        icon: "warning",
        title: "Alasan pembatalan obat is required!",
      });
    }

    setConfirmationCancel(false);

    var detail =
      pharmacyDelivery.details.length != 0
        ? pharmacyDelivery.details[0]
        : pharmacyDelivery.details;

    if (detail.length == 0) {
      return Swal.fire({
        icon: "warning",
        title: "Pharmacy Delivery Data Not Found",
      });
    }

    const form = new FormData();

    form.append("orderId", detail.order_id);

    if (otherReason) {
      form.append("reason", `Lainnya (${cancelField.reason})`);
    } else {
      form.append("reason", cancelField.reason);
    }

    dispatch(cancelOrder(form))
      .then(() => {
        setTimeout(
          () =>
            Swal.fire({
              icon: "success",
              title: "Order Canceled",
              showConfirmButton: false,
            }),
          1000
        );
        setTimeout(() => (window.location = "/pharmacy-delivery"), 2000);
      })
      .catch((err) => {
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          }).then(function () {
            window.location.reload();
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          }).then(function () {
            window.location.reload();
          });
        }
      });
  };

  const renderPickup = () => {
    var detail =
      pharmacyDelivery.details.length != 0
        ? pharmacyDelivery.details[0]
        : pharmacyDelivery.details;

    return roleUser != 44 ?
      (
        pharmacyDelivery.loading ? (
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-secondary"
            style={{ marginRight: "10px", width: "100px" }}
            disabled
          >
            Loading...
          </button>
        </div>
      ) : (
        <div className="d-flex justify-content-end">
          {detail.order_type == 19 ? (
            <button
              type="button"
              className="btn btn-danger"
              style={{ marginRight: "10px", width: "125px" }}
              onClick={() => setConfirmationCancel(true)}
            >
              Cancel Order
            </button>
          ) : null}

          <button
            type="button"
            className="btn btn-primary"
            style={{ marginRight: "10px", width: "100px" }}
            onClick={() => setConfirmationModal(true)}
          >
            Pickup
          </button>
        </div>
      )
    ) : null;
  };

  const renderConfirmation = () => {
    var detail =
      pharmacyDelivery.details.length != 0
        ? pharmacyDelivery.details[0]
        : pharmacyDelivery.details;

    return (
      <Modal
        size="lg"
        show={confirmationModal}
        onHide={() => setConfirmationModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Konfirmasi Data Pasien
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row className="mt-2">
            <Col xs={3}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Nama Pasien</span>
                <span>:</span>
              </div>
            </Col>

            <Col xs={9}>
              <span style={{ fontWeight: "600" }}>
                {detail.destinationName ? detail.destinationName : "-"}
              </span>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col xs={3}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Tanggal Lahir Pasien</span>
                <span>:</span>
              </div>
            </Col>

            <Col xs={9}>
              <span style={{ fontWeight: "600" }}>
                {detail.birthday ? moment(detail.birthday).format("DD MMM YYYY") : "-"}
              </span>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col xs={3}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Nomor HP Pasien</span>
                <span>:</span>
              </div>
            </Col>

            <Col xs={9}>
              <span style={{ fontWeight: "600" }}>
                {detail.destinationContactPhone ? (
                  <a
                    style={{ color: "blue", textDecoration: "underline" }}
                    href={detail.destinationContactPhoneWa}
                    target="_blank"
                  >
                    {"+62" + detail.destinationContactPhone}{" "}
                  </a>
                ) : (
                  "-"
                )}
              </span>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col xs={3}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Alamat Pengiriman Detail</span>
                <span>:</span>
              </div>
            </Col>

            <Col xs={9}>
              <span style={{ fontWeight: "600" }}>
                {`${detail.destinationNote ? detail.destinationNote : "-"} | ${detail.destinationAddress ? detail.destinationAddress : "-"}`}
              </span>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col xs={3}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Metode Pengiriman</span>
                <span>:</span>
              </div>
            </Col>

            <Col xs={9}>
              <span style={{ fontWeight: "600" }}>
                {detail.name ? detail.name : "-"}
              </span>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col xs={3}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Jadwal Pengiriman</span>
                <span>:</span>
              </div>
            </Col>

            <Col xs={9}>
              <span style={{ fontWeight: "600" }}>
                {detail.schedule_time
                  ? `${moment(detail.schedule_date).format(
                      "DD MMM YYYY"
                    )} ${detail.schedule_time.substr(0, 5)}`
                  : "-"}
              </span>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col
              md
              className="form-group"
              style={{ display: "flex", justifyContent: "right" }}
            >
              <button
                type="button"
                className="btn btn-primary btn-block"
                style={{ width: "25%", backgroundColor: "#3183C5" }}
                onClick={() => handlePickup()}
              >
                Pickup
              </button>
            </Col>

            <Col
              md
              className="form-group"
              style={{ display: "flex", justifyContent: "left" }}
            >
              <button
                type="button"
                className="btn btn-primary btn-block"
                style={{ width: "25%", backgroundColor: "#c90e0e" }}
                onClick={() => setConfirmationModal(false)}
              >
                Cancel
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  const renderCancel = () => {
    return (
      <Modal
        size="lg"
        show={confirmationCancel}
        onHide={() => setConfirmationCancel(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Apakah Anda yakin akan membatalkan pesanan?
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row className="mt-2">
            <Col md>
              <div className="form-group">
                <label>
                  Alasan pembatalan obat <span style={{ color: "red" }}>*</span>
                </label>
                <div className="input-group d-flex flex-column">
                  <select
                    className="form-control mb-2"
                    style={{ width: '100%' }}
                    value={reason}
                    onChange={(e) => handleCancelOption(e)}
                  >
                    <option value="">
                      Alasan pembatalan
                    </option>
                    {reasons.map(item =>
                      (<option value={item}>{item}</option>)
                    )}
                  </select>
                </div>
                {
                  reason.trim().toLowerCase() === "lainnya" ?
                    <>
                      <label>
                        Keterangan <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="input-group d-flex flex-column">
                        <input
                          type="text"
                          className="form-control w-100"
                          placeholder="Keterangan"
                          style={{ height: "38px" }}
                          value={reason.trim().toLowerCase() === "lainnya" ? cancelField.reason : ""}
                          disabled={disableInput}
                          onChange={(e) =>
                            setCancelField({
                              reason: e.target.value,
                            })
                          }
                        />
                      </div>
                    </>
                    : ''
                }
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col
              md
              className="form-group"
              style={{ display: "flex", justifyContent: "right" }}
            >
              <button
                type="button"
                className="btn btn-primary btn-block"
                style={{ width: "25%", backgroundColor: "#3183C5" }}
                onClick={() => handleCancel()}
              >
                Confirm
              </button>
            </Col>

            <Col
              md
              className="form-group"
              style={{ display: "flex", justifyContent: "left" }}
            >
              <button
                type="button"
                className="btn btn-primary btn-block"
                style={{ width: "25%", backgroundColor: "#c90e0e" }}
                onClick={() => setConfirmationCancel(false)}
              >
                Cancel
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  const handleEditAddress = () => {
    if (editAddressField.note === "") {
      return Swal.fire({
        icon: "warning",
        title: "Alamat pengiriman detail is required!",
      });
    }

    setConfirmationEditAddress(false);

    var detail =
      pharmacyDelivery.details.length != 0
        ? pharmacyDelivery.details[0]
        : pharmacyDelivery.details;

    if (detail.length == 0) {
      return Swal.fire({
        icon: "warning",
        title: "Pharmacy Delivery Data Not Found",
      });
    }

    const form = new FormData();

    form.append("addressId", detail.address_id);
    form.append("note", editAddressField.note);

    dispatch(submitAddressNote(form))
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Alamat Pengiriman Detail Updated",
        }).then(function () {
          let values = {
            partnerId: localStorage.getItem("parentId"),
          };

          let query = queryString.stringify(values);
          dispatch(getDetailPharmacy(detail.order_id, query));
        });
      })
      .catch((err) => {
        if (err.response) {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
          }).then(function () {
            window.location.reload();
          });
        } else {
          Swal.fire({
            icon: "error",
            text: err.message,
          }).then(function () {
            window.location.reload();
          });
        }
      });
  };

  const handleCancelEditAddress = () => {
    setConfirmationEditAddress(false);

    var detail =
      pharmacyDelivery.details.length != 0
        ? pharmacyDelivery.details[0]
        : pharmacyDelivery.details;
    
    setEditAddressField({note: detail.destinationNote});
  };

  const renderEditAddress = () => {
    return (
      <Modal
        size="lg"
        show={confirmationEditAddress}
        onHide={() => handleCancelEditAddress()}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Ubah Alamat Pengiriman Detail
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row className="mt-2">
            <Col md>
              <div className="form-group">
                <label>
                  Alamat pengiriman detail <span style={{ color: "red" }}>*</span>
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Masukkan alamat pengiriman detail"
                    style={{ height: "38px" }}
                    value={editAddressField.note}
                    onChange={(e) =>
                      setEditAddressField({
                        note: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="pt-2">
                  {mapAddress}
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col
              md
              className="form-group"
              style={{ display: "flex", justifyContent: "right" }}
            >
              <button
                type="button"
                className="btn btn-primary btn-block"
                style={{ width: "25%", backgroundColor: "#3183C5" }}
                onClick={() => handleEditAddress()}
              >
                Confirm
              </button>
            </Col>

            <Col
              md
              className="form-group"
              style={{ display: "flex", justifyContent: "left" }}
            >
              <button
                type="button"
                className="btn btn-primary btn-block"
                style={{ width: "25%", backgroundColor: "#c90e0e" }}
                onClick={() => handleCancelEditAddress()}
              >
                Cancel
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  const alert = () => {
    let detail =
      pharmacyDelivery.details.length != 0
        ? pharmacyDelivery.details[0]
        : pharmacyDelivery.details;

    return detail.is_req_pickup ? (
      <Alert variant="warning" style={{ width: "100%" }}>
        {`Request pickup berhasil. Pencarian driver ${
          detail.name
        } akan dilakukan otomatis pukul ${detail.schedule_time.substr(0, 5)}`}
      </Alert>
    ) : null;
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const renderDetails = () => {
    var detail =
      pharmacyDelivery.details.length != 0
        ? pharmacyDelivery.details[0]
        : pharmacyDelivery.details;

    return pharmacyDelivery.loading ? (
      <tr>
        <td>
          <Loading />
        </td>
      </tr>
    ) : (
      <>
        <tr>
          <td style={{ width: "180px" }}>ID Order</td>
          <td>{detail.order_id ? detail.order_id : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Status Order</td>
          <td>{detail.order_status ? detail.order_status : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Nama Pasien</td>
          <td>{detail.destinationName ? detail.destinationName : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Tanggal Lahir Pasien</td>
          <td>
            {detail.birthday ? moment(detail.birthday).format("DD MMM YYYY") : "-"}
          </td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Nomor HP Pasien</td>
          <td>
            {detail.destinationContactPhone ? (
              <a
                style={{ color: "blue", textDecoration: "underline" }}
                href={detail.destinationContactPhoneWa}
                target="_blank"
              >
                {"+62" + detail.destinationContactPhone}{" "}
              </a>
            ) : (
              "-"
            )}
          </td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Alamat Pengiriman Detail</td>
          <td>{`${detail.destinationNote ? detail.destinationNote : "-"} | ${detail.destinationAddress ? detail.destinationAddress : "-"}`}
          <button
            type="button"
            className="btn btn-info btn-sm"
            style={{ marginLeft: "10px" }}
            onClick={() => setConfirmationEditAddress(true)}
          >
            Edit
          </button>
          </td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Metode Pengiriman</td>
          <td>{detail.name ? detail.name : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Biaya Pengiriman</td>
          <td>
            {detail.transportFee ? formatter.format(detail.transportFee) : "-"}
          </td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Jadwal Pengiriman</td>
          <td>
            {detail.schedule_time
              ? `${moment(detail.schedule_date).format(
                  "DD MMM YYYY"
                )} ${detail.schedule_time.substr(0, 5)}`
              : "-"}
          </td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Status Pengiriman</td>
          <td>{detail.delivery_status ? detail.delivery_status : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Lacak Pengiriman</td>  
          <td>
            {detail.live_tracking_url ? (
              <a
                style={{ color: "blue", textDecoration: "underline" }}
                href={detail.live_tracking_url}
                target="_blank"
              >
                Track Delivery{" "}
              </a>
            ) : (
              "-"
            )}
          </td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Resi Pengiriman</td>
          <td>{detail.booking_id ? detail.booking_id : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Nama Driver</td>
          <td>{detail.driver_name ? detail.driver_name : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Nomor HP Driver</td>
          <td>
            {detail.driver_phone ? (
              <a
                style={{ color: "blue", textDecoration: "underline" }}
                href={detail.driver_phoneWa}
                target="_blank"
              >
                {"+62" + detail.driver_phone}{" "}
              </a>
            ) : (
              "-"
            )}
          </td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Nama Penerima</td>
          <td>{detail.receiver_name ? detail.receiver_name : "-"}</td>
        </tr>

        <tr>
          <td style={{ width: "180px" }}>Alasan Pembatalan</td>
          <td>
            {detail.cancellation_reason ? detail.cancellation_reason : "-"}
          </td>
        </tr>

        {(customFields.remarks && customFields.remarks?.isShown == '1') && (
          <tr>
            <td style={{ width: "180px" }}>Nama Dokter</td>
            <td>
              {detail.order_type == 2
                ? detail.doctor_name
                  ? detail.doctor_name
                  : "-"
                : detail.order_type == 19
                  ? detail.remarks
                    ? detail.remarks
                    : "-"
                  : detail.order_type == 13
                    ? "-"
                    : "-"}
            </td>
          </tr>
        )}

        <tr>
          <td style={{ width: "180px" }}>Jadwal Telekonsultasi</td>
          <td>
            {detail.scheduleDate
              ? moment(detail.scheduleDate).format("DD MMM YYYY")
              : "-"}
          </td>
        </tr>

        {(customFields.orderDate && customFields.orderDate.isShown === '1') && (
          <tr>
            <td style={{ width: "180px" }}>Tanggal Resep</td>
            <td>
              {detail.order_type == 2 || detail.order_type == 13
                ? detail.prescriptionDate
                  ? detail.prescriptionDate
                  : "-"
                : detail.order_type == 19
                ? detail.orderDate
                  ? detail.orderDate
                  : "-"
                : "-"}
            </td>
          </tr>
        )} 

        {(customFields.queueNumber && customFields.queueNumber.isShown === '1') && (
          <tr>
            <td style={{ width: "180px" }}>Nomor Antrian</td>
            <td>
              {detail.queueNumber || '-'}
            </td>
          </tr>
        )}
        {(customFields.drugType && customFields.drugType.isShown === '1') && (
          <tr>
            <td style={{ width: "180px" }}>Jenis Obat</td>
            <td>
              {detail.drugType || '-'}
            </td>
          </tr>
        )} 
        {(detail.order_type == 19 )&&(<tr>
          <td style={{ width:"180px"}}>Harga obat</td>
          <td>
            { !detail?.drugPrice ? `-`:`${detail?.drugPrice && formatCurrency(detail?.drugPrice)}`}
          </td>
        </tr>)} 
      </>
    );
  };

  const renderAwbEmpty = () => {
    return (
      <Modal
        size="sm"
        show={awbModal}
        onHide={() => setAwbModal(false)}
        centered
        >
        <Modal.Body>
          <Row className="mt-2">
            <Col
                md
                className="form-group"
                style={{ display: "flex", justifyContent: "center" }}
              >
              Silahkan lakukan request pickup sebelum mencetak label.
            </Col>
          </Row>
          <Row className="mt-2">
            <Col
              md
              className="form-group"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                type="button"
                className="btn btn-primary btn-block"
                style={{ width: "25%", backgroundColor: "#c90e0e" }}
                onClick={() => setAwbModal(false)}
              >
                Cancel
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };
  return (
    <DashboardLayout>
      <div className="row revenue-title">
        <div className="col">
          <hr />
        </div>

        <div>Data Order Detail</div>

        <div className="col">
          <hr />
        </div>
      </div>

      <div className="table-wrapper">
        <div className="wrapper-form-title">
          <span className="form-title">Data Order</span>
        </div>

        <div className="row pb-4">
          <div className="col">{renderPrint()}</div>
          <div className="col">{renderPickup()}</div>
        </div>

        {alert()}

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <tbody className="text-left">{renderDetails()}</tbody>
          </table>
        </div>

        <div className="form-title">
          <hr />
        </div>
      </div>
      {renderConfirmation()}
      {renderCancel()}
      {renderEditAddress()}
      {renderAwbEmpty()}
    </DashboardLayout>
  );
};

export default PharmacyDeliveryDetail;
